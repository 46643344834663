export default {
  search: [
    { name: '兑换码', type: 'text', fieldName: 'code', value: '', width: '110px' },
    { name: '收件电话', placeholder: '收件电话', type: 'text', fieldName: 'recipient_tel', value: '', width: '130px' },
    { name: '兑换电话', placeholder: '兑换电话', type: 'text', fieldName: 'exchange_tel', value: '', width: '130px' },
    { name: '产品名称', type: 'text', fieldName: 'goods_title', value: '', width: '110px' },
    {
      name: '组合产品',
      placeholder: '组合产品',
      type: 'select',
      width: '110px',
      fieldName: 'is_combination',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '产品类型',
      placeholder: '产品类型',
      type: 'select',
      width: '110px',
      fieldName: 'productFomate',
      value: '',
      options: [
        { label: '直充产品', value: 'is_charge' },
        { label: '虚拟产品', value: 'is_virtual' },
        { label: '普通产品', value: 'is_normal' },
      ],
    },
    {
      name: '分销商',
      type: 'select',
      fieldName: 'agent_id',
      value: '',
      options: [],
      remote: true,
      props: {
        url: '/agent/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '客户名称',
      type: 'select',
      fieldName: 'company_id',
      value: '',
      remote: true,
      width: '110px',
      props: {
        url: '/company/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '年份',
      placeholder: '年份',
      type: 'date',
      fieldName: 'year',
      value: '',
      format: 'yyyy',
      dateType: 'year',
      width: '100px',
    },
    {
      name: '场景',
      placeholder: '场景',
      type: 'select',
      fieldName: 'scene_id',
      value: '',
      remote: true,
      width: '100px',
      props: {
        url: '/scene/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '用途',
      placeholder: '用途',
      type: 'select',
      width: '100px',
      fieldName: 'purpose_id',
      value: '',
      remote: true,
      props: {
        url: '/purpose/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '档位',
      placeholder: '档位',
      type: 'select',
      width: '110px',
      fieldName: 'price_title_id',
      value: '',
      remote: true,
      props: {
        url: '/price-title/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      fieldName: 'sale_type',
      type: 'select',
      name: '销售渠道',
      width: '150px',
      value: '',
      options: [],
      constantDict: 'saleType',
    },
    {
      name: '兑换码类型',
      type: 'select',
      fieldName: 'type_id',
      width: '110px',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/ticket-type/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      fieldName: 'ticket_line_id',
      type: 'select',
      name: '产品线',
      value: '',
      options: [],
      remote: true,
      width: '150px',
      props: {
        url: '/ticket-line/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      fieldName: 'carrier_type',
      type: 'select',
      name: '载体类型',
      width: '150px',
      value: '',
      options: [],
      constantDict: 'carriertype',
    },
    {
      fieldName: 'use_type',
      name: '出库类型',
      type: 'select',
      value: '',
      options: [],
      remote: true,
      props: {
        url: '/ticket-use-type/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '供应商',
      type: 'select',
      fieldName: 'supplier_id',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/supplier/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '结算方式',
      type: 'select',
      fieldName: 'settlement_type',
      value: '',
      width: '130px',
      options: [],
      constantDict: 'settlemenType',
    },
    {
      name: '结账',
      placeholder: '结账',
      type: 'select',
      fieldName: 'is_checkout',
      value: '',
      width: '100px',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '发货类型',
      type: 'select',
      placeholder: '发货类型',
      fieldName: 'delivery_type',
      width: '110px',
      value: '',
      options: [
        { label: '自备货', value: 0 },
        { label: '代发', value: 1 },
      ],
    },
    {
      name: '发货',
      type: 'select',
      placeholder: '发货',
      fieldName: 'is_ship',
      width: '150px',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '录入快递',
      placeholder: '录入快递',
      type: 'select',
      width: '110px',
      fieldName: 'is_express',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '发短信',
      placeholder: '发短信',
      type: 'select',
      width: '100px',
      fieldName: 'is_sms',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '完结',
      type: 'select',
      placeholder: '完结',
      width: '100px',
      fieldName: 'is_end',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '星标客户',
      type: 'select',
      placeholder: '星标客户',
      width: '110px',
      fieldName: 'is_star_customer',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      fieldName: 'question_type',
      placeholder: '问题订单',
      constantDict: 'question',
      type: 'select',
      width: '110px',
      name: '问题订单',
      value: '',
      options: [],
    },
    {
      name: '兑换码问题',
      type: 'select',
      placeholder: '兑换码问题',
      fieldName: 'is_question',
      width: '150px',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    { name: '兑换', type: 'datetimerange', format: 'yyyy-MM-dd HH:mm:ss', fieldName: 'begin_create_time||end_create_time', value: [], width: '360px' },
    { name: '发货', type: 'daterange', fieldName: 'begin_ship_date||end_ship_date', value: [], width: '300px' },
    // {
    //   fieldName: 'spec_id',
    //   type: 'treeSelect',
    //   name: '规格',
    //   placeholder: '规格',
    //   value: '',
    //   options: [],
    //   width: '110px',
    //   required: true,
    //   remote: true,
    //   multiple: false,
    //   props: {
    //     url: '/spec/list',
    //     value: 'id',
    //     label: 'name',
    //     params: { type: 1 },
    //   },
    // },
    // { name: '兑换数量', placeholder: '兑换数量', type: 'text', fieldName: 'exchange_num', value: '', width: '110px' },
    // {
    //   name: '供应商',
    //   placeholder: '供应商',
    //   type: 'select',
    //   fieldName: 'supplier_id',
    //   value: '',
    //   remote: true,
    //   width: '100px',
    //   props: {
    //     url: '/supplier/list',
    //     value: 'id',
    //     label: 'name',
    //   },
    // },
    // {
    //   name: '订单类型',
    //   placeholder: '订单类型',
    //   type: 'select',
    //   width: '110px',
    //   fieldName: 'type',
    //   value: '',
    //   options: [
    //     { label: '兑换券', value: 1 },
    //     { label: '礼品券', value: 2 },
    //   ],
    // },
    // {
    //   name: '发货方式',
    //   type: 'select',
    //   placeholder: '发货方式',
    //   fieldName: 'delivery_type',
    //   width: '110px',
    //   value: '',
    //   options: [
    //     { label: '自备货', value: 0 },
    //     { label: '代发', value: 1 },
    //   ],
    // },
    // {
    //   name: '实际产品',
    //   placeholder: '实际产品',
    //   type: 'select',
    //   width: '110px',
    //   fieldName: 'is_ticket_goods',
    //   value: '',
    //   options: [
    //     { label: '是', value: 1 },
    //     { label: '否', value: 0 },
    //   ],
    // },
    // {
    //   name: '快递公司',
    //   placeholder: '快递公司',
    //   type: 'select',
    //   width: '110px',
    //   fieldName: 'express_id',
    //   value: '',
    //   remote: true,
    //   props: {
    //     url: '/express/list',
    //     value: 'id',
    //     label: 'name',
    //   },
    // },
    // { name: '快递单号', type: 'text', fieldName: 'express_sn', value: '', width: '110px' },
  ],
};
