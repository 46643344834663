export default {
  created() {
    if (this.$route.name === 'order-shipping') {
      this.$route.meta.slideSearch = false;
      this.table = [
        // {
        //   type: 'expand',
        //   prop: 'expand',
        //   func: (ops) => (`<div style="font-size: 14px;">
        //         <div class="mt-20"><span class="mr-10" style="color: #99a9bf">联系人:</span>${ops.recipient_name}</div>
        //         <div class="mt-20"><span class="mr-10" style="color: #99a9bf">收件人电话:</span>${ops.recipient_tel}</div>
        //         <div class="mt-20"><span class="mr-10" style="color: #99a9bf">${ops.is_charge ? '直充账号' : '收件人地址'}:</span>${!ops.is_charge ? `${ops.recipient_province_name} ${ops.recipient_city_name} ${ops.recipient_area_name}  ` : ''}${ops.recipient_address}</div>
        //         <div class="mt-20"><span class="mr-10" style="color: #99a9bf">备注:</span>${ops.memo || '无'}</div>
        //       </div>`),
        // },
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '客户名称', prop: 'agent_name', width: '150px' },
        { name: '下单时间', prop: 'create_time', width: '150px' },
        {
          name: '产品名称',
          width: '150px',
          func: (ops) => {
            let str = ops.title;
            if (ops.code_sort !== 0) {
              str += `<p style="color: #BF311F;">${ops.parent_question_name}</p>`;
            }
            return str;
          },
        },
        {
          name: '规格',
          width: '100px',
          func: (ops) => {
            let str = '';
            if (ops.spec.pic) {
              str += `<img src="${ops.spec.pic}" width="25" height="25" style="vertical-align: middle" /> `;
            }
            str += ops.spec.name;
            str += ops.spec.memo;
            return str;
          },
        },
        { name: '单价', prop: 'generation_price', width: '100px' },
        { name: '数量', prop: 'exchange_num', width: '100px' },
        { name: '金额', prop: 'pay_amount', width: '100px' },
        { name: '联系人', prop: 'recipient_name', width: '100px' },
        { name: '收件人电话', prop: 'recipient_tel', width: '100px' },
        { name: '地址', prop: 'address', width: '150px', func: (ops) => `</span>${!ops.is_charge ? `${ops.recipient_province_name} ${ops.recipient_city_name} ${ops.recipient_area_name}  ` : ''}${ops.recipient_address}` },
        { name: '备注', prop: 'memo', width: '100px', func: (ops) => ops.memo || '无' },
        {
          name: '发货',
          prop: 'is_ship',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_ship),
          switchChange: (ops) => this.switchChange(ops, 'ship'),
        },
        {
          name: '结账',
          prop: 'is_checkout',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_checkout),
          switchChange: (ops) => this.switchChange(ops, 'checkout'),
        },
        { name: '完结', prop: 'pay_amount', width: '100px', func: (ops) => (ops.is_end ? '是' : '否') },
        { name: '结算方式', prop: 'settlement_type_name', width: '100px' },
        { name: '问题订单', prop: 'question_name', width: '100px' },
        { name: '操作', prop: 'htmlButton', fixed: 'right' },
      ];
      this.search = [
        {
          name: '客户名称',
          type: 'select',
          fieldName: 'agent_id',
          value: '',
          remote: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '供应商',
          type: 'select',
          fieldName: 'supplier_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/supplier/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '产品名称', type: 'text', fieldName: 'goods_title', value: '', width: '110px' },
        { name: '收件电话', placeholder: '收件电话', type: 'text', fieldName: 'recipient_tel', value: '', width: '130px' },
        {
          name: '产品类型',
          placeholder: '产品类型',
          type: 'select',
          fieldName: 'productFomate',
          value: '',
          options: [
            { label: '直充产品', value: 'is_charge' },
            { label: '虚拟产品', value: 'is_virtual' },
            { label: '普通产品', value: 'is_normal' },
          ],
        },
        {
          name: '发货',
          type: 'select',
          placeholder: '发货',
          fieldName: 'is_ship',
          width: '150px',
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          name: '完结',
          type: 'select',
          placeholder: '完结',
          width: '100px',
          fieldName: 'is_end',
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          name: '问题订单',
          type: 'select',
          placeholder: '问题订单',
          fieldName: 'is_question',
          width: '150px',
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          name: '结账',
          placeholder: '结账',
          type: 'select',
          fieldName: 'is_checkout',
          value: '',
          width: '100px',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        { name: '兑换', type: 'datetimerange', format: 'yyyy-MM-dd HH:mm:ss', fieldName: 'begin_create_time||end_create_time', value: [], width: '360px' },
      ];
    }
  },
};
