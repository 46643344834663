<template>
  <div>
    <lz-dialog
      title="跟进记录"
      :is-close-btn="false"
      ref="lzDialog">
      <lz-list
        :config="config"
        :table="table"
        :is-router="false"
        :search-btn="searchBtn"
        @search-btn-click="handleCommonBtnCLick"
        @table-btn-click="handleCommonBtnCLick"
        :search="search"
        ref="lzList">
      </lz-list>
    </lz-dialog>
    <lz-dialog
      :width="500"
      :dialogBtns="[
        { key: 'save', name: '保存', type: 'primary' }
      ]"
      @dialog-btn-click="handleCommonBtnCLick"
      title="新增跟进记录"
      ref="lzDialogForm">
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      title: '',
      search: [
        { name: '订单id', fieldName: 'order_id', value: '' },
      ],
      searchBtn: [
        { name: '添加', type: 'danger', key: 'add' },
      ],
      table: [
        { name: '跟进时间', prop: 'data_time' },
        { name: '备注', prop: 'memo' },
        { name: '操作', prop: 'action' },
        { name: '操作人', prop: 'nick_name' },
      ],
      form: [
        {
          name: '跟进时间',
          type: 'date',
          fieldName: 'value',
          value: '',
          required: true,
        },
        {
          name: '备注',
          type: 'textarea',
          fieldName: 'memo',
          value: '',
          required: true,
        },
        {
          name: 'id',
          type: 'text',
          fieldName: 'order_id',
          value: '',
          hide: true,
        },
      ],
      config: {
        url: '/record/list',
      },
    };
  },
  methods: {
    init(id, isAdd) {
      this.search[0].value = id;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzList.getData();
        if (isAdd) this.handleCommonBtnCLick('add');
      });
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'add':
          this.handleDialogForm();
          break;
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/record/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialogForm.dialogVisible = false;
        }
          break;
        default:
          break;
      }
    },
    handleDialogForm(response = null) {
      this.$refs.lzDialogForm.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {
            order_id: this.search[0].value,
            value: dayjs().format('YYYY-MM-DD'),
          },
        });
      });
    },
  },
};
</script>
