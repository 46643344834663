<template>
  <lz-dialog
    title="快递信息"
    :dialogBtns="dialogBtns"
    @dialog-btn-click="handleCommonBtnCLick"
    ref="lzDialog">
    <lz-card title="基础信息">
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-card>
    <lz-card
      title="快递信息">
      <el-button :disabled="disabled" slot="right" type="primary" @click="handleAddExpress">新增</el-button>
      <el-table
        :data="list"
        :header-cell-style="{backgroundColor: '#f6f8fa'}"
        border>
        <el-table-column label="快递公司">
          <template slot-scope="scope">
            <el-select
              filterable
              style="width: 100%;"
              v-model="scope.row.express_id">
              <el-option
                v-for="item in expressList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="快递费用">
          <template slot-scope="scope">
            <el-input-number :min="0" v-model="scope.row.express_price" clearable placeholder="快递费用" style="width: 100%;"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="快递单号">
          <template slot-scope="scope">
            <el-input v-model="scope.row.express_sn" clearable placeholder="请输入快递单号"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.memo" clearable placeholder="请输入备注"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.id != undefined"
              :underline="false"
              style="margin-right: 10px;"
              @click="$refs.action.init('sms', id, scope.row.id);"
              type="warning">
              发短信
            </el-link>
            <el-link
              v-if="scope.row.id != undefined"
              :underline="false"
              style="margin-right: 10px;"
              @click="$refs.action.handleGetExpress(scope.row)"
              type="warning">
              查询
            </el-link>
            <el-link
              :underline="false"
              @click="list.splice(scope.$index, 1)"
              type="danger">
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </lz-card>
    <action ref="action"></action>
    <sms-list ref="smsList"></sms-list>
  </lz-dialog>
</template>
<script>
import action from './action';
import smsList from './smsList';

export default {
  components: {
    action,
    smsList,
  },
  data() {
    return {
      list: [],
      id: '',
      expressList: [],
      dialogBtns: [],
      form: [
        {
          name: '预发货日期',
          type: 'date',
          fieldName: 'ship_date_ready',
          value: '',
          required: true,
        },
      ],
    };
  },
  computed: {
    disabled() {
      return this.list.some((v) => v.express_id === '' || v.express_price === '' || v.express_sn === '');
    },
  },
  mounted() {
    this.$baseHttp.get('/express/list?page_size=1000').then((response) => {
      this.expressList = response.data.data;
    });
  },
  methods: {
    async init(id) {
      this.id = id;
      this.dialogBtns = [];
      this.$baseHttp.get(`/order/detail?id=${id}`).then((response) => {
        this.$refs.lzDialog.dialogVisible = true;
        this.list = response.data.express;
        if (response.data.sms_count > 0) {
          this.dialogBtns.push({ key: 'smsList', name: '短信记录', type: 'warning' });
        }
        this.dialogBtns.push({ key: 'save', name: '保存', type: 'primary' });
        this.$nextTick(() => {
          this.$refs.lzForm.setValue({
            data: response.data,
          });
        });
      });
    },
    handleAddExpress() {
      this.list.push({
        express_id: '',
        express_price: 0,
        express_sn: '',
        memo: '',
      });
    },
    async handleCommonBtnCLick(type) {
      if (type === 'smsList') {
        this.$refs.smsList.init(this.id);
        return;
      }
      const params = this.$refs.lzForm.getValue();
      if (!params) return;
      params.id = this.id;
      params.express = this.list;
      const response = await this.$baseHttp.post('/order/update', params);
      if (!response) return;
      this.$message.success('操作成功');
      this.$refs.lzDialog.dialogVisible = false;
    },
  },
};
</script>
