<template>
  <div>
    <lz-list
      selection
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      :default-expand-all="false"
      table-height="97%"
      :searchFomate="searchFomate"
      @getdata="handleGetData"
      @upload-success="handleUploadSuccess"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick"
      @table-checkbox-change="handleCheckboxChange">
      <div v-if="showTotal" slot="pagePosition" style="font-size: 13px;">
        <template v-if="$route.name == 'order-list'">
          <div data-flex="">
            <div>实际订单数:<span class="ml-10 color-primary">{{ total_other }}</span> 条</div>
            <div class="ml-10">附属订单数:<span class="ml-10 color-primary">{{ total_sub }}</span> 条</div>
          </div>
          <div data-flex="" class="mt-10">
            <div>金额:<span class="ml-10 color-primary">{{ total_pay_amount }}</span> 元</div>
            <div class="ml-10">补付金额:<span class="ml-10 color-primary">{{ total_recharge_amount }}</span> 元</div>
          </div>
        </template>
        <template v-if="$route.name == 'order-shipping'">
          <div>金额:<span class="ml-10 color-primary">{{ total_pay_amount }}</span> 元</div>
        </template>
      </div>
    </lz-list>
    <action ref="action" type="order" @success="$refs.lzList.getData()"></action>
    <record ref="record"></record>
    <express ref="express"></express>
    <views ref="views" @success="handleTableBtnClick"></views>
    <manual ref="manual" @success="$refs.lzList.getData()"></manual>
  </div>
</template>
<script>
import { getAccessToken } from '@/utils/auth';
import { getSearchValue } from '@/utils/search';
import cfg from '@/config';
import action from './action';
import config from './config';
import record from './record';
import express from './express';
import manual from './manual';
import views from './details';
import shipping from './shipping';

export default {
  mixins: [shipping],
  components: {
    action,
    record,
    express,
    manual,
    views,
  },
  data() {
    return {
      showTotal: false,
      total_other: 0,
      total_sub: 0,
      total_pay_amount: 0,
      total_recharge_amount: 0,
      search: JSON.parse(JSON.stringify(config.search)),
      config: {
        url: '',
        setTableFormat: (list) => {
          list.forEach((el) => {
            if (el.btn_express) el.htmlButton.push({ name: '快递', key: 'express', type: 'warning' });
            if (el.btn_cancel) el.htmlButton.push({ name: '作废', key: 'cancel', type: 'danger' });
            if (el.btn_sms && this.$route.name !== 'order-shipping') el.htmlButton.push({ name: '短信', key: 'sms', type: 'warning' });
            if (el.btn_end) el.htmlButton.push({ name: '完结', key: 'end', type: 'danger' });
            if (el.is_cancel) el.htmlButton.push({ name: '已作废', type: 'warning', disabled: true });
            if (el.btn_cancel_ship) el.htmlButton.push({ name: '撤销发货', key: 'cancel_ship', type: 'danger' });
            if (this.showRecorList) el.htmlButton.push({ name: '跟进记录', key: 'record', type: 'success' });
          });
          return list;
        },
      },
      table: [
        {
          type: 'expand',
          prop: 'expand',
          func: (ops) => (`<div style="font-size: 14px;">
                <div class="mt-20"><span class="mr-10" style="color: #99a9bf">联系人:</span>${ops.recipient_name}</div>
                <div class="mt-20"><span class="mr-10" style="color: #99a9bf">收件人电话:</span>${ops.recipient_tel}</div>
                <div class="mt-20"><span class="mr-10" style="color: #99a9bf">${ops.is_charge ? '直充账号' : '收件人地址'}:</span>${!ops.is_charge ? `${ops.recipient_province_name} ${ops.recipient_city_name} ${ops.recipient_area_name}  ` : ''}${ops.recipient_address}</div>
                <div class="mt-20"><span class="mr-10" style="color: #99a9bf">备注:</span>${ops.memo || '无'}</div>
              </div>`),
        },
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '兑换日期', prop: 'create_time', width: '90px' },
        { name: '预发货日期', prop: 'ship_date_ready', width: '100px' },
        {
          name: '兑换码',
          width: '130px',
          func: (ops) => {
            let str = ops.code;
            if (ops.code_sort !== 0) {
              str += `<span style="color: #BF311F;"> <span>${ops.code_sort}</span><span>-${ops.delivery_sort}</span></span>
              <p style="color: #BF311F;">${ops.parent_question_name}</p>`;
            }
            return str;
          },
        },
        { name: '收件人', prop: 'recipient_name', width: '90px' },
        { name: '产品名称', prop: 'title', width: '150' },
        {
          name: '规格',
          width: '100px',
          func: (ops) => {
            let str = '';
            if (ops.spec.pic) {
              str += `<img src="${ops.spec.pic}" width="25" height="25" style="vertical-align: middle" /> `;
            }
            str += ops.spec.name;
            str += ops.spec.memo;
            return str;
          },
        },
        { name: '数量', prop: 'exchange_num', width: '50px' },
        { name: '消费金额', prop: 'pay_amount' },
        { name: '档位', prop: 'price_title_name' },
        {
          name: '发货类型',
          width: '80px',
          func: (ops) => (ops.delivery_type ? '代发' : '自备货'),
        },
        { name: '兑换类型', prop: 'type_name', width: '100px' },
        { name: '供应商', prop: 'supplier_name', width: '100px' },
        // { name: '兑换码类型', prop: 'type_name', width: '130px' },
        // { name: '销售渠道', prop: 'sale_type_name', width: '130px' },
        // { name: '载体类型', prop: 'carrier_type_name', width: '130px' },
        // { name: '产品线', prop: 'ticket_line_name', width: '130px' },
        {
          name: '发货',
          prop: 'is_ship',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_ship),
          switchChange: (ops) => this.switchChange(ops, 'ship'),
        },
        {
          name: '星标客户',
          prop: 'is_star_customer',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !ops.btn_star_customer,
          switchChange: (ops) => this.switchChange(ops, 'star_customer'),
        },
        {
          name: '结账',
          prop: 'is_checkout',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_checkout),
          switchChange: (ops) => this.switchChange(ops, 'checkout'),
        },
        { name: '问题订单', prop: 'question_name' },
        // {
        //   name: '来源',
        //   width: '80px',
        //   func: (ops) => (ops.order_type === 1 ? '人工兑换' : '客户兑换'),
        // },
        { name: '操作', prop: 'htmlButton', fixed: 'right' },
      ],
      showRecorList: false,
      values: [],
    };
  },
  computed: {
    isShiping() {
      return this.$route.name === 'order-shipping';
    },
  },
  created() {
    if (this.$route.name === 'order-list') {
      this.config.url = '/order/list';
    }
    if (this.$route.name === 'order-shipping') {
      this.config.url = '/shipping/order/list';
    }
  },
  mounted() {
    this.$delete(this.$route.meta.roleBtn, 'star_customer');
    this.$delete(this.$route.meta.roleBtn, 'cancel_ship');
    this.$delete(this.$route.meta.roleBtn, 'invoice');
    this.$delete(this.$route.meta.roleBtn, 'record_save');
    if (this.$route.meta.roleBtn.record_list) {
      this.showRecorList = true;
      // this.$delete(this.$route.meta.roleBtn, 'record_list');
    }
  },
  methods: {
    handleGetData(response) {
      this.showTotal = response.data.length > 0;
      this.total_other = response.total_other;
      this.total_sub = response.total_sub;
      this.total_pay_amount = response.total_pay_amount;
      this.total_recharge_amount = response.total_recharge_amount;
    },
    handleCheckboxChange(values) {
      this.values = values.map((r) => r.id);
    },
    handleTableBtnClick(type, element) {
      const params = {
        type,
        id: element.id,
      };
      switch (type) {
        case 'delete':
          this.$baseConfirm({
            content: '是否删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`${this.isShiping ? '/shipping' : ''}/order/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('操作成功');
              this.$refs.lzList.getData();
            },
          });
          break;
        case 'sms':
          this.$refs.action.init('sms', element.id);
          break;
        case 'save': {
          const target = this.$refs.lzList.list.find((r) => r.id === element.id);
          if (target) {
            this.$refs.lzList.getOneData(target, {
              id: target.id,
            });
          }
        }
          break;
        case 'cancel':
          this.$baseConfirm({
            content: '是否确定作废',
            callConfirm: async () => {
              params.value = 1;
              const response = await this.$baseHttp.post('/order/action', params);
              if (!response) return;
              this.$message.success('操作成功');
              this.$refs.lzList.getOneData(element, {
                id: element.id,
              });
            },
          });
          break;
        case 'end':
          this.$baseConfirm({
            content: '是否确定完结',
            callConfirm: async () => {
              params.value = 1;
              const response = await this.$baseHttp.post('/order/action', params);
              if (!response) return;
              this.$message.success('操作成功');
              this.$refs.lzList.getOneData(element, {
                id: element.id,
              });
            },
          });
          break;
        case 'cancel_ship':
          this.$baseConfirm({
            content: '是否撤销发货',
            callConfirm: async () => {
              params.value = 1;
              const response = await this.$baseHttp.post('/order/action', params);
              if (!response) return;
              this.$message.success('操作成功');
              this.$refs.lzList.getOneData(element, {
                id: element.id,
              });
            },
          });
          break;
        case 'record':
          this.$refs.record.init(element.id);
          break;
        case 'express':
          this.$refs.express.init(element.id);
          break;
        case 'edit':
          this.$refs.views.init(element.id);
          // this.$router.push({
          //   name: 'order-view',
          //   query: { id: element.id },
          // });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      if (type === 'add') {
        this.$refs.manual.init();
        return;
      }
      switch (type) {
        // 发短信
        case 'sms':
          this.$refs.action.init('sms', this.values);
          break;
        // 预发货日期
        case 'ship_date_ready':
          this.$refs.action.init('ship_date_ready', this.values);
          break;
        // 导出
        case 'export': {
          const query = getSearchValue(this.search);
          query.token = getAccessToken();
          delete query.page;
          delete query.page_size;
          query.is_export = 1;
          this.$baseConfirm({
            content: '请选择导出类型',
            confirmText: '全部信息导出',
            cancalText: '发货信息导出',
            callConfirm: async () => {
              query.export_type = 1;
              window.open(`${cfg.api}/${this.isShiping ? 'shipping/' : ''}order/list?${this.$jsonToUrl(query)}`);
            },
            callCancel: (action) => {
              if (action === 'close') return;
              query.export_type = 2;
              window.open(`${cfg.api}/${this.isShiping ? 'shipping/' : ''}order/list?${this.$jsonToUrl(query)}`);
            },
          });
        }
          break;
        default:
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          this.$baseConfirm({
            content: '是否确定操作',
            callConfirm: async () => {
              const response = await this.$baseHttp.post('/order/action', {
                type,
                ids: this.values.toString(),
                value: 1,
              });
              if (type === 'ship') this.$refs.lzList.getData();
              if (!response) return;
              this.$message.success('操作成功');
              if (type !== 'ship') this.$refs.lzList.getData();
            },
          });
          break;
      }
    },
    handleUploadSuccess(response) {
      const params = {
        attachment_id: response.data[0].id,
      };
      this.$baseConfirm({
        content: '请选择导入类型',
        confirmText: '快递单导入',
        cancalText: '月结导入',
        callConfirm: async () => {
          params.import_type = 1;
          this.handleUploadResponse(params);
        },
        callCancel: (action) => {
          if (action === 'close') return;
          params.import_type = 2;
          this.handleUploadResponse(params);
        },
      });
    },
    async handleUploadResponse(params) {
      const response = await this.$baseHttp.post(`${this.isShiping ? '/shipping' : ''}/order/import`, params);
      if (!response) return;
      this.$message.success('操作成功');
      this.$refs.lzList.getData();
    },
    switchChange(row, type) {
      const params = {
        id: row.id,
      };
      const url = type !== 'checkout' ? '/order/action' : '/order/action';
      // if (type !== 'checkout') {
      //   params.type = type;
      //   params.value = row[`is_${type}`];
      // } else {
      //   params[`is_${type}`] = row[`is_${type}`];
      // }
      params.type = type;
      params.value = row[`is_${type}`];
      this.$baseHttp.post(url, params).then((response) => {
        if (!response) {
          this.$set(row, `is_${type}`, !row[`is_${type}`]);
          return;
        }
        if (type === 'ship') {
          this.$refs.lzList.getOneData(row, {
            id: row.id,
          });
        }
        if (type === 'star_customer' && row[`is_${type}`]) {
          this.$refs.record.init(row.id, true);
        }
        this.$message.success('操作成功');
      });
    },
    searchFomate(params) {
      if (params.productFomate === 'is_charge') {
        this.$set(params, 'is_charge', 1);
      } else if (params.productFomate === 'is_virtual') {
        this.$set(params, 'is_virtual', 1);
      } else if (params.productFomate === 'is_normal') {
        this.$set(params, 'is_charge', 0);
        this.$set(params, 'is_virtual', 0);
      }
      delete params.productFomate; // eslint-disable-line
      return params;
    },
  },
};
</script>
<style lang="scss" scoped></style>
