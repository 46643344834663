<template>
  <lz-dialog
    title="人工兑换"
    :dialogBtns="dialogBtns"
    @dialog-btn-click="handleCommonBtnCLick"
    ref="lzDialog">
    <template v-for="parent in formList">
      <div :key="parent.value">
      <template v-if="parent.value == 'exchange' && info">
        <el-alert
          :closable="false"
          :title="`您可以兑换${info.exchange_num }个产品 已兑换${info.num}个产品 当前已选择${productTotal}个产品`"
          type="warning"
          style="margin: 20px 0 10px;"
          show-icon>
        </el-alert>
        <!-- <el-alert
          :closable="false"
          :title="`您可以兑换${info.total_price }元 已兑换${info.exchange_price}元 当前已兑换${priceTotal}元`"
          type="warning"
          style="margin-bottom: 10px;"
          show-icon>
        </el-alert> -->
        <lz-select-product
          :exchange="info.exchange_num - info.num"
          :selectGoods="info.goods.goods"
          :addGoods="info.goods.add_goods"
          :goods="goods"
          :info="info"
          @preview-product="handlePreview"
          :total="productTotal">
        </lz-select-product>
      </template>
        <lz-card :title="parent.name">
          <div slot="right" v-if="parent.value == 'exchange'" data-flex="main:right">
            <el-button type="danger"  @click="handleCopyMsg">保存信息</el-button>
            <el-button type="success" @click="handleGetCopyMsg">读取信息</el-button>
          </div>
          <lz-form :form="parent.list" :col="3" ref="lzForm" @switch-change="handleSwitchChange"></lz-form>
        </lz-card>
      </div>
    </template>
    <preview ref="preview"></preview>
  </lz-dialog>
</template>
<script>
import preview from '@/views/product/preview';

export default {
  components: {
    preview,
  },
  computed: {
    productTotal() {
      return this.goods.reduce((total, item) => Number(total) + (Number(item.exchange_num)), 0);
    },
    priceTotal() {
      return this.goods.reduce((total, item) => Number(total) + (Number(item.price_title_price) * Number(item.exchange_num)), 0);
    },
  },
  data() {
    return {
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      info: null,
      goods: [],
      formList: [
        {
          name: '基础信息',
          value: 'base',
          list: [
            { name: '订单id', fieldName: 'ticket_id', value: '', type: 'text', disabled: true },
            { name: '兑换码', fieldName: 'code', value: '', type: 'text', disabled: true },
            { name: '密码', fieldName: 'pwd', value: '', type: 'text', disabled: true },
            { name: '客户名称', fieldName: 'company_name', value: '', type: 'text', disabled: true },
            { name: '年份', fieldName: 'year', value: '', type: 'text', disabled: true },
            { name: '礼一价格', fieldName: 'price_title_name', value: '', type: 'text', disabled: true },
            { name: '产品用途', fieldName: 'purpose_name', value: '', type: 'text', disabled: true },
            { name: '应用场景', fieldName: 'scene_name', value: '', type: 'text', disabled: true },
            { name: '过期时间', fieldName: 'begin_time||end_time', value: [], type: 'daterange', disabled: true, width: '400px' },
          ],
        },
        {
          name: '兑换信息',
          value: 'exchange',
          list: [
            { name: '收件人姓名', fieldName: 'recipient_name', value: '', type: 'text', required: true, disabled: false },
            { name: '收件人电话', fieldName: 'recipient_tel', value: '', type: 'text', required: true, disabled: false },
            { name: '备用电话', fieldName: 'recipient_tel_backup', value: '', type: 'text', required: false, disabled: false },
            {
              fieldName: 'recipient_province_id||recipient_city_id||recipient_area_id',
              type: 'cascader',
              name: '联系地址',
              value: [],
              required: true,
              remote: true,
              disabled: false,
              options: [],
              props: {
                url: '/common/area',
                value: 'value',
                label: 'label',
                params: { level: 3 },
              },
            },
            { name: '详细地址', fieldName: 'recipient_address', value: '', type: 'text', required: true, disabled: false, width: '50%' },
            {
              name: '与收件人一致',
              fieldName: 'switch',
              value: false,
              type: 'switch',
              required: false,
              disabled: false,
              width: '100%',
            },
            { name: '兑换人姓名', fieldName: 'exchange_name', value: '', type: 'text', required: true, disabled: false },
            { name: '兑换人电话', fieldName: 'exchange_tel', value: '', type: 'text', required: true, disabled: false },
            { name: '备注', fieldName: 'memo', value: '', type: 'textarea', required: false, disabled: false, width: '100%' },
          ],
        },
      ],
    };
  },
  methods: {
    init() {
      this.$basePrompt({
        content: '请输入兑换码',
        inputType: 'text',
        callConfirm: async (value) => {
          const params = {
            code: value,
          };
          const response = await this.$baseHttp.get('/code/get', { params });
          if (!response) return;
          [
            ...response.data.goods.goods,
            ...response.data.goods.add_goods,
          ].forEach((element) => {
            // 如果当前产品存在out_goods里面
            const hasOutGoods = response.data.goods.out_goods.find((out) => out.id === element.id);
            if (hasOutGoods) {
              this.$set(element, 'memo', hasOutGoods.memo);
              this.$set(element, 'is_global', hasOutGoods.is_global);
            }
          });
          this.info = response.data;
          this.$refs.lzDialog.dialogVisible = true;
          this.formList.forEach((parent, index) => {
            this.$nextTick(() => {
              this.$refs.lzForm[index].setValue({
                data: this.info,
              });
            });
          });
        },
      });
    },
    async handleCommonBtnCLick() {
      if (this.goods.length === 0) {
        this.$message.error('请选择产品');
        return;
      }
      this.goods.forEach((el) => {
        this.$delete(el.spec, 'label');
        this.$delete(el.spec, 'value');
      });
      const params = this.$refs.lzForm[1].getValue();
      if (!params) return;
      params.goods = this.goods;
      params.code_id = this.info.id;
      const response = await this.$baseHttp.post('/order/save', params);
      if (!response) return;
      this.$message.success('操作成功');
      this.$refs.lzDialog.dialogVisible = false;
      this.$emit('success');
    },
    handleCopyMsg() {
      localStorage.setItem('exchangeMsg', JSON.stringify(this.formList[1].list));
      this.$message.success('保存信息成功');
    },
    handleGetCopyMsg() {
      const exchangeMsg = localStorage.getItem('exchangeMsg');
      if (!exchangeMsg || exchangeMsg === '') {
        this.$message.error('暂无信息');
        return;
      }
      const msg = JSON.parse(exchangeMsg);
      this.formList[1].list = [];
      setTimeout(() => {
        this.formList[1].list = msg;
      }, 100);
    },
    handleSwitchChange(element) {
      const params = this.$refs.lzForm[1].getValue({
        isValidate: false,
      });
      if (params.recipient_name === '' || params.recipient_tel === '') {
        this.$message.error('收件人电话和姓名不能为空');
        this.$set(element, 'value', false);
        return;
      }
      this.formList[1].list.filter((r) => ['exchange_name', 'exchange_tel'].includes(r.fieldName)).forEach((el) => {
        if (el.fieldName === 'exchange_name') {
          this.$set(el, 'value', params.recipient_name);
        } else {
          this.$set(el, 'value', params.recipient_tel);
        }
      });
    },
    handlePreview(id) {
      this.$refs.preview.init(id);
    },
  },
};
</script>
